.contact-container {
  margin: 0 auto;
  width: 90vw;
  font-size: 1.3rem;
  padding-top: 2rem;
  text-align: center;
}
.contact-title {
  text-align: left;
  width: 70vw;
  padding-top: 3rem;
  padding-left: 8rem;
}
.email-title {
  text-align: left;
  width: 70vw;
  padding-left: 8rem;
  padding-top: 3rem;
}

.contact-text {
  text-align: left;
  width: 70vw;
  padding-right: 27rem;
  padding-bottom:3rem;
}
.email-text {
  text-align: left;
  width: 70vw;
  padding-right: 41rem;
  padding-bottom:3rem;
}
