.homepage-container{
    
}

.description {
    margin: 0 auto;
    width: 90vw;
    font-size: 1.5rem;
    padding-top: 5rem;
    text-align: center;
}

.icon{
    padding: 2rem;
    height: 3rem;
    width: 3rem;
}

