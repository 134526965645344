.navbar {
    display:flex;
    justify-content: space-between;
}

.logo{
    font-size: 1.5rem;
    padding: 0.9rem 1rem 1rem 2rem;
    text-decoration: none;
}

.logo:visited{
    color:black;
}

.navbar-list{
    display:flex;
    justify-content: flex-end;
    list-style: none;
}

.nav-item{
    color:black;
    font-size: 1.2rem;
    text-decoration: none;
    padding: 2rem;
    
}

.nav-item:visited{
    color:black;    
}   