.about-container {
    margin: 0 auto;
    width: 90vw;
    font-size: 1.3rem;
    padding-top: 5rem;
    text-align: center;
}

.about-title {
    text-align: left;
    width: 70vw;
    padding-top: 3rem;
    padding-left: 8rem;
}

.about-text {
    text-align: left;
    width: 70vw;
    padding-top: 3rem;
    padding-left: 8rem;
}
.about-text-2 {
    text-align: left;
    width: 70vw;
    padding-top: 3rem;
    padding-left: 8rem;
    padding-bottom: 5rem;
}
.about-project-link{
    text-decoration: none;
    text-align: left;
}